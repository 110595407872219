<template>
  <b-card-code title="Custom Toolbar">
    <b-card-text>You have freedom to create your own custom toolbar</b-card-text>

    <quill-editor
      v-model="content"
      :options="editorOption"
    >
      <div
        id="toolbar"
        slot="toolbar"
      >
        <!-- Add a bold button -->
        <button class="ql-bold">
          Bold
        </button>
        <button class="ql-italic">
          Italic
        </button>

        <!-- Add font size dropdown -->
        <select class="ql-size">
          <option value="small" />
          <!-- Note a missing, thus falsy value, is used to reset to default -->
          <option selected />
          <option value="large" />
          <option value="huge" />
        </select>

        <select class="ql-font">
          <option selected="selected" />
          <option value="serif" />
          <option value="monospace" />
        </select>

        <!-- Add subscript and superscript buttons -->
        <button
          class="ql-script"
          value="sub"
        />
        <button
          class="ql-script"
          value="super"
        />

        <!-- You can also add your own -->
        <button
          class="w-auto btn btn-primary"
          @click="customButtonClick"
        >
          Click here
        </button>
      </div>
    </quill-editor>

    <template #code>
      {{ codeCustom }}
    </template>
  </b-card-code>
</template>

<script>
import { BCardText } from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { quillEditor } from 'vue-quill-editor'
import { codeCustom } from './code'

export default {
  components: {
    quillEditor,
    BCardCode,
    BCardText,
  },
  data() {
    return {
      codeCustom,
      editorOption: {
        modules: {
          toolbar: '#toolbar',
        },
      },
      content: `<p></p>`,
    }
  },
  methods: {
    customButtonClick() {
      // eslint-disable-next-line
      alert('Button clicked!')
    },
  },
}
</script>
